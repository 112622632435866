<template>
  <v-row no-gutters
    class="align-self-stretch"
  >
    <v-col cols="12"
      class="d-flex pa-0"
    >
      <v-btn-toggle class="align-stretch flex-grow-1 justify-center"
        tile
        borderless
        active-class
        color="accent lighten-1"
        background-color="primary"
      >
        <v-btn
          class="menu py-3 d-md-flex flex-column"
          plain
          v-for="menu in menus"
          :key="menu.menu_id"
          :to="'/' + menu.slug"
          :value="menu.slug"
        >
          <span class="font-weight-bold"
            :class="setting.font != 'serif' ? 'text-overline' : setting.font"
          >
            {{ menu.slug }}
          </span>
          <span class="menu-name font-weight-bold">
            {{ menu.display_name || menu.menu_name }}
          </span>
        </v-btn>
      </v-btn-toggle>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
  },

  props: {
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      topUrl: '/',
    }
  },

  computed: {
    mdiIconName() {
      return name => 'mdi-' + name
    }
  },

  methods: {
  }
};
</script>

<style scoped>
.menu {
  min-width: 120px !important;
}
.menu-name {
  font-size: 1.2em;
}
.v-application .text-overline {
  line-height: 1.5em;
}
>>> .v-item-group .v-btn {
  height: inherit !important;
}
>>> .v-item-group .v-btn__content {
  flex-direction: column-reverse;
}
>>> .v-btn .text-button {
  line-height: 1.5em;
}
>>> .v-btn .text-button {
  font-size: 1.2em !important;
}
>>> .v-btn .text-caption {
  font-size: .9em !important;
}
>>> .v-btn .v-icon {
  color: inherit !important;
}
>>> .v-btn__content {
  opacity: 1 !important;
}
</style>
