import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#FFFFFF',
        secondary: '#AAAAAA',
        accent: '#000000',
        error: '#ffeb3b',
        warning: '#ffc107',
        info: '#ff5722',
        success: '#4caf50'
      },
      dark: {
        primary: '#000000',
        secondary: '#555555',
        accent: '#FFFFFF',
        error: '#ffeb3b',
        warning: '#ffc107',
        info: '#ff5722',
        success: '#4caf50'
      },
    }
  }
});
