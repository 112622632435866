<template>
  <v-footer app
    padless
    fixed
    absolute
    color="primary"
  >
    <!-- Navigation buttons -->
    <v-bottom-navigation v-model="value"
      class="alilgn-end"
      horizontal
      height="45"
      color="accent"
      background-color="primary"
    >
      <v-btn
        class="d-md-flex px-4 py-1"
        height="inherit"
        min-width="50"
        plain
        v-for="menu in menus"
        :key="menu.menu_id"
        :to="'/' + menu.slug"
        :value="menu.slug"
      >
        <span class="d-none d-md-inline text-button font-weight-bold text-uppercase">
          {{ menu.slug }}
        </span>
        <v-icon class="mr-1">
          {{ mdiIconName(menu.icon_name) }}
        </v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!-- Copyright -->
    <copy-right
      :siteData="siteData"
      class="mb-1"
    ></copy-right>
  </v-footer>
</template>

<script>
import CopyRight from '@/components/_CopyRight.vue'

export default {
  components: {
    'copy-right': CopyRight,
  },

  props: {
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      value: ''
    };
  },

  computed: {
    mdiIconName() {
      return name => 'mdi-' + name
    }
  },

  created() {
  }
};
</script>

<style scoped>
>>> .v-item-group.v-bottom-navigation {
  box-shadow: none;
}
>>> .v-btn__content {
  opacity: 1 !important;
}

</style>
