<template>
  <v-app>
    <nav-drawer
      v-if="!isTop"
      :siteData="siteData"
      :setting="siteSetting"
      :menus="siteMenus"
      :openNavDrawer="openNavDrawer"
      @drawerClosed="openNavDrawer = false"
    ></nav-drawer>

    <header-bar
      v-if="!isTop"
      :siteData="siteData"
      :setting="siteSetting"
      :menus="siteMenus"
      @navIconClicked="openNavDrawer = true"
    ></header-bar>

    <v-main
      :class="bgColorClass"
      :style="{backgroundImage: 'url(' + siteSetting.site_bg_image_url + ')'}"
    >
      <router-view
        v-if="readyToLoadContents"
        :siteApi="apiEndpoint"
        :siteData="siteData"
        :setting="siteSetting"
        :menus="siteMenus"
      ></router-view>
    </v-main>

    <footer-bar
      :siteData="siteData"
      :setting="siteSetting"
      :menus="siteMenus"
    ></footer-bar>
  </v-app>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import axios from 'axios'
import { API_ENDPOINT } from '@/literals.js'
import { AlertApiError } from '@/module.js'
import Header from '@/components/_Header.vue'
import Footer from '@/components/_Footer.vue'
import NavDrawer from '@/components/_NavDrawer.vue'

export default {
  components: {
    'header-bar': Header,
    'footer-bar': Footer,
    'nav-drawer': NavDrawer,
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      siteData: {},
      siteSetting: {},
      siteMenus: [],
      openNavDrawer: false,
      hostName: document.location.hostname,
      apiEndpoint : API_ENDPOINT + '/site/',
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    readyToLoadContents() {
      return this.siteData.shop_id > 0
    },
    isTop () {
      return this.$route.path === '/'
    },
    bgColorClass() {
      if (this.siteSetting.site_bg_image_url) {
        return ''
      }
      else if (this.siteSetting.is_dark_theme) {
        return 'secondary darken-1'
      }
      else {
        return 'secondary lighten-1'
      }
    }
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    // if (sessionStorage.getItem('siteData') !== null &&
    //     sessionStorage.getItem('siteSetting') !== null &&
    //     sessionStorage.getItem('siteMenus') !== null)
    // {
    //   this.siteData = JSON.parse(sessionStorage.getItem('siteData'));
    //   this.siteSetting = JSON.parse(sessionStorage.getItem('siteSetting'));
    //   this.siteMenus = JSON.parse(sessionStorage.getItem('siteMenus'));
    //
    //   // Set theme and color
    //   if (this.siteSetting.is_dark_theme) {
    //     this.$vuetify.theme.dark = true
    //     this.$vuetify.theme.themes.dark.primary = this.siteSetting.primary_color
    //     this.$vuetify.theme.themes.dark.secondary = this.siteSetting.secondary_color
    //     this.$vuetify.theme.themes.dark.accent = this.siteSetting.accent_color
    //   } else {
    //     this.$vuetify.theme.themes.light.primary = this.siteSetting.primary_color
    //     this.$vuetify.theme.themes.light.secondary = this.siteSetting.secondary_color
    //     this.$vuetify.theme.themes.light.accent = this.siteSetting.accent_color
    //   }
    // } else {
      // Get shop data via api
      this.getSiteData()
    // }
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    getSiteData() {
      axios({
        method: 'GET',
        url: this.apiEndpoint + 'init/',
        params: {host: this.hostName}
      })
      .then(response => {
        this.siteData = response.data.site_data
        this.siteSetting = response.data.site_setting
        this.siteMenus = response.data.site_menus

        // sessionStorage.setItem("siteData", JSON.stringify(this.siteData))
        // sessionStorage.setItem("siteSetting", JSON.stringify(this.siteSetting))
        // sessionStorage.setItem("siteMenus", JSON.stringify(this.siteMenus))

        // Set font family
        document.documentElement.style.setProperty('--font-family', this.siteSetting.font)

        // Set theme and color
        if (this.siteSetting.is_dark_theme) {
          this.$vuetify.theme.dark = true
          this.$vuetify.theme.themes.dark.primary = this.siteSetting.primary_color
          this.$vuetify.theme.themes.dark.secondary = this.siteSetting.secondary_color
          this.$vuetify.theme.themes.dark.accent = this.siteSetting.accent_color
        } else {
          this.$vuetify.theme.themes.light.primary = this.siteSetting.primary_color
          this.$vuetify.theme.themes.light.secondary = this.siteSetting.secondary_color
          this.$vuetify.theme.themes.light.accent = this.siteSetting.accent_color
        }
      })
      .catch(error => AlertApiError(error))
    },
  }
}
</script>

<style>
/* Reset */
ul {
  list-style: none;
}
img {
  max-width: 100%;
}

:root {
  --font-family: メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main.v-main {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat-y;
}

.d-border {
  border: 1px dotted red !important;
}

.v-application p {
  margin-bottom: 5px;
}

.ql-size-small {
  font-size: .85em;
}
.ql-size-large {
  font-size: 1.5em;
  line-height: 1.3em;
}
.ql-size-huge {
  font-size: 2.5em;
  line-height: 1.2em;
}
.ql-align-left {
  text-align: left;
}
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
</style>
