<template>
  <v-sheet>
    <v-app-bar
      class="pr-1"
      v-if="$vuetify.breakpoint.smAndDown"
      color="secondary"
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-app-bar-nav-icon
        class="ml-0"
        @click.stop="$emit('navIconClicked')"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-btn text depressed class="px-2">
        <v-icon>mdi-clock-outline</v-icon>
        {{ bizHours }}
      </v-btn>
      <v-btn text depressed class="px-2">
        <v-icon>mdi-phone-outgoing</v-icon>
        {{ siteData.phone }}
      </v-btn>
    </v-app-bar>

    <!-- Image -->
    <v-sheet
      class="header-logo"
    >
      <router-link :to="{ name: 'home' }">
        <v-img
          position="center center"
          width="100vw"
          :src="$vuetify.breakpoint.smAndUp ? setting.header_bg_image_url : setting.header_bg_image_mobile_url"
          :cover="$vuetify.breakpoint.xs"
        >
          <img
            class="pc-logo"
            :src="$vuetify.breakpoint.smAndUp ? setting.header_image_url : setting.header_image_mobile_url"
          />
        </v-img>
      </router-link>
    </v-sheet>

    <!-- Menu bar -->
    <nav-bar
      class="d-md-flex"
      v-if="$vuetify.breakpoint.mdAndUp"
      :siteData="siteData"
      :setting="setting"
      :menus="menus"
    ></nav-bar>
  </v-sheet>
</template>

<script>
import NavBar from '@/components/_NavBar.vue'

export default {
  components: {
    'nav-bar': NavBar,
  },

  props: {
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      topUrl: '/',
      navHeight: 70,
      openNavDrawer: false,
      isHeaderGone: false
    }
  },

  computed: {
    bizHours() {
      const openAry = this.siteData.opening_hour.split(':')
      const closeAry = this.siteData.closing_hour.split(':')
      return openAry[0] + ':' + openAry[1] + '~' + closeAry[0] + ':' + closeAry[1]
    },
  },

  methods: {
  }
};
</script>

<style scoped>
>>> .v-toolbar__content {
  overflow: hidden;
  padding: 0;
}
/* >>> .v-image .v-responsive__content {
  width: unset !important;
} */
.header-logo {
  text-align: center;
}
.pc-logo {
  min-height: 100%;
  max-width: 100%;
}
</style>
