<template>
  <v-row no-gutters>
    <v-col cols="12"
      class="text-caption text-center"
    >
      &copy; {{ (new Date()).getFullYear() }}
      <span class="ml-1">
        {{ siteData.shop_name }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
  },

  props: {
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },

  data() {
    return {
    };
  },

  computed: {
  },

  created() {
  }
};
</script>

<style scoped>
</style>
