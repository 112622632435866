import axios from 'axios'
import moment from 'moment'
import { MESSAGE } from '@/literals.js';

//***************************************************
//
// Export functions
//
//***************************************************
export {
  AlertApiError,
}

//***************************************************
// display api error alert
//
function AlertApiError(error) {
  if (error.response !== undefined) {
    alert(MESSAGE.failedApiRequest + error.response.data + error.response.status)
  } else {
    alert(MESSAGE.serverError + error)
  }
}

//***************************************************
//
// Export classes
//
//***************************************************
// Class for general API calls
//
export class ApiTool {
  constructor (endpoint, siteData) {
    this.siteApiEndpoint = endpoint
    this.shopId = siteData.shop_id
    // this.token = sessionStorage.getItem('requestToken')
  }

  //
  // GET APIコール
  //
  getRequest(apiPartial, queries) {
    return new Promise((resolve, reject) => {
      axios({
        ...{method: 'GET'},
        ...{url: this.siteApiEndpoint + this.shopId + '/' + apiPartial},
        ...{params: queries}
        // ...{headers: {'Authorization': 'Bearer ' + this.token},
      })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  //
  // APIコール フォームデータで更新
  //
  apiReqWithData(method, apiPartial, data) {
    return new Promise((resolve, reject) => {
      axios({
        ...{ method: method },
        ...{ url: this.siteApiEndpoint + this.shopId + '/' + apiPartial },
        ...{ data: data }
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
    })
  }
}

//***************************************************
// Class for business hour methods
//
export class BizHour {
  constructor (siteData) {
    this.openingHour = siteData.opening_hour
    this.closingHour = siteData.closing_hour

    this.openingHourNum = Number(siteData.opening_hour.split(':')[0])
    this.closingHourNum = Number(siteData.closing_hour.split(':')[0])

    this.closingHour24 = this.closingHourNum <= this.openingHourNum ?
      (this.closingHourNum + 24) + ':00' : this.closingHour
  }

  //
  // 日付から営業開始日時の取得（日またぎ問題の考慮）
  // return string （'YYYY-MM-DD HH:mm'）
  getBizOpening(date) {
    const datetime = new Date(date)
    let tmp = new Date(datetime)

    //閉店時刻が翌日の場合
    if (this.closingHourNum <= this.openingHourNum) {
      if (datetime.getHours() <= this.closingHourNum) {
        tmp = moment(datetime).subtract(1, 'd')
      }
    }
    else { //閉店時刻が同日（24時前閉店）の場合
      if (datetime.getHours() >= this.closingHourNum) {
        tmp = moment(datetime).add(1, 'd')
      }
    }
    return moment(moment(tmp).format('YYYY-MM-DD') +' '+ this.openingHour).format('YYYY-MM-DD HH:mm')
  }

  //
  // 日付から営業終了日時の取得（日またぎ問題の考慮）
  // return string （'YYYY-MM-DD HH:mm'）
  getBizClosing(date) {
    const datetime = new Date(date)
    let tmp = new Date(datetime)

    //閉店時刻が翌日の場合
    if (this.closingHourNum <= this.openingHourNum) {
      if (datetime.getHours() >= this.closingHourNum) {
        tmp = moment(datetime).add(1, 'd')
      }
    }
    //同日閉店で閉店時刻後の場合
    else if (datetime.getHours() >= this.closingHourNum) {
      tmp = moment(datetime).add(1, 'd')
    }
    return moment(moment(tmp).format('YYYY-MM-DD') +' '+ this.closingHour).format('YYYY-MM-DD HH:mm') //.add(1, 'm')
  }

  //
  // 日付と時刻から日またぎ修正した日時を返す
  //
  getAdjustedDatetime(dateStr, timeStr) {
    const hourNum = Number(timeStr.split(':')[0])
    let tmp = moment(dateStr)

    if (hourNum < this.openingHourNum) {
      tmp.add(1, 'd')
    }
    return tmp.format('YYYY-MM-DD') + ' ' + ('0'+ hourNum).slice(-2) + ':' + timeStr.split(':')[1]
  }

  //
  // 引数dtがbaseDate日の営業時間内かどうか
  // return Boolean
  isInBizHours(dt, baseDate) {
    const datetime = new Date(dt)

    const opening = this.getBizOpening(baseDate)
    const closing = this.getBizClosing(baseDate)

    return (moment(datetime).diff(opening) >= 0 && moment(datetime).diff(closing) <= 0) ? true : false
  }

  //
  // 営業時間のHour数値を配列で返す
  //
  getBizHourArray() {
    const hourArray = []

    //閉店時刻が翌日の場合
    if (this.closingHourNum <= this.openingHourNum) {
      for (let i = this.openingHourNum; i <= 23; i++) {
        hourArray.push({ id: i, value: ('0'+ i).slice(-2) })
      }
      for (let i = 0; i <= this.closingHourNum; i++) {
        hourArray.push({ id: i, value: ('0'+ i).slice(-2) })
      }
    } else {
      for (let i = this.openingHourNum; i <= this.closingHourNum; i++) {
        hourArray.push({ id: i, value: ('0'+ i).slice(-2) })
      }
    }

    return hourArray
  }

  //
  // フォーム用の分数値を配列で返す
  //
  getBizMinArray() {
    const minArray = []

    for (let i = 0; i <= 45; i += 15) {
      minArray.push({ id: i, value: ('0'+ i).slice(-2) })
    }

    return minArray
  }
}
