//***************************************************
//
// Export constants
//
//***************************************************
export {
  MESSAGE,
  STATUS_CODE,
  SYSTEM_PROVIDER,
  API_ENDPOINT,
  LIFF_ID,
  LAYZLOAD_IMG,
  AGE_AUTH_IMG_FOR_DARK,
  AGE_AUTH_IMG_FOR_LIGHT,
  MAILMAGA_IMG,
}

// messages for alert
const  MESSAGE = {
    // General error
    programError : '内部エラーが発生しました。\n\n',
    serverError : 'サーバーでエラーが発生しました。\n\n',
    failedsiteData : '店舗情報取得中にエラーが発生しました。\nもう一度試してください。\n\n',
    // API request error
    failedApiRequest: 'リクエスト中にエラー発生しました。\nしばらくしてからもう一度試してください。\n\n',
    failedApiGet: '情報取得中にエラーが発生しました。\nしばらくしてからもう一度試してください。\n\n',
    requestNotFound: '該当のデータが存在しない様です。',
    // Validation error
    validationFormInput: '未記入項目やエラー項目がないかご確認ください',
}

// HTTP Status code
const STATUS_CODE = {
    unauthorized: 401,
    errServer: 600,
    // Token error
    errIssueTokenFailed: 610,
    errVerifyTokenFailed: 611,
    errTokenInvalid: 613,
    errTokenNotFound: 614,
    // DB error
    dbErr: 700,
    dbErrUnintendedRowsAffected: 701,
    dbErrNoRowsAffected: 707,
}

const API_ENDPOINT = process.env.NODE_ENV === 'production' ?
  'https://site-api.finebooking.shop' : 'http://site-api.localhost:8082'

const LIFF_ID = process.env.NODE_ENV === 'production' ?
  '1654462880-maD3V8eO' : '1654462880-2O57yG1b'

const SYSTEM_PROVIDER = {
  name: 'FINE TECHNOLOGY',
  email: 'finebooking.shop@gmail.com',
  since: '2021',
  systemName: 'FINEBOOKING',
}

const LAYZLOAD_IMG = 'assets/lazy-src.png'
const AGE_AUTH_IMG_FOR_DARK = 'assets/age-auth_dark.png'
const AGE_AUTH_IMG_FOR_LIGHT = 'assets/age-auth_light.png'
const MAILMAGA_IMG = 'assets/mailmaga.png'
