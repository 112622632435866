import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'top',
    component: () => import(/* webpackChunkName: 'top' */ '@/views/Top.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: 'home' */ '@/views/Home.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/cast',
    name: 'cast',
    component: () => import(/* webpackChunkName: 'castList' */ '@/views/CastList.vue'),
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/cast/:castId/',
    name: 'profile',
    component: () => import(/* webpackChunkName: 'profile' */ '@/views/Profile.vue'),
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/shift',
    name: 'shift',
    component: () => import(/* webpackChunkName: 'shiftList' */ '@/views/ShiftList.vue'),
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/system',
    name: 'system',
    component: () => import(/* webpackChunkName: 'system' */ '@/views/System.vue'),
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/event',
    name: 'event',
    component: () => import(/* webpackChunkName: 'event' */ '@/views/EventList.vue'),
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/diary',
    name: 'diary',
    component: () => import(/* webpackChunkName: 'diary' */ '@/views/DiaryList.vue'),
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/video',
    name: 'video',
    component: () => import(/* webpackChunkName: 'video' */ '@/views/VideoList.vue'),
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/mailmaga-unsubscribe/:address',
    name: 'mailmagaUnsubscribe',
    component: () => import(/* webpackChunkName: 'unsubscribe' */ '@/views/MailmagaUnsubscribe.vue'),
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/custom/:id',
    name: 'custom',
    component: () => import(/* webpackChunkName: 'custom' */ '@/views/CustomPage.vue'),
    props: true,
    meta: { requiresAuth: false },
  },
  // 404も追加
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
