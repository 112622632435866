<template>
  <v-navigation-drawer :value="openNavDrawer"
    fixed
    temporary
    color="primary"
    @input="drawerState"
  >
    <v-list nav dense>
      <v-list-item-group
        color="accent"
      >
        <v-list-item
          v-for="menu in menus"
          :key="menu.menu_id"
          :to="'/' + menu.slug"
          :value="menu.slug"
        >
          <v-list-item-icon>
            <v-icon v-text="mdiIconName(menu.icon_name)"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="menu.display_name || menu.menu_name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <copy-right
        :siteData="siteData"
        class="mb-1"
      ></copy-right>
    </template>
  </v-navigation-drawer>
</template>

<script>
import CopyRight from '@/components/_CopyRight.vue'

export default {
  components: {
    'copy-right': CopyRight,
  },

  props: {
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
    openNavDrawer: {
      type: Boolean,
      required: true,
      default: false,
    }
  },

  data() {
    return {
    };
  },

  computed: {
    mdiIconName() {
      return name => 'mdi-' + name
    }
  },

  methods: {
    drawerState(boolean) {
      if (!boolean) {
        this.$emit('drawerClosed')
      }
    }
  }
};
</script>

<style scoped>
</style>
